import {useLocation, useMatches, useNavigate} from "react-router";

import {addRetURL, useOnMountUnsafe} from "~/lib";
import {locationChange} from "~/reducers/app";

import type {RouteProps} from "~/@types/components/features/RouteProps";
import {useAppDispatch} from "~/store";
import {useSelector} from "react-redux";
import {isAnonymous} from "~/reducers/account";
import {getSlugInfo} from "~/reducers/navigation";
import {Slug} from "~/config";

function RouteComponent() {
    return "Route Component not defined";
}

export default function Route({
    page,
    slug,
    Component = RouteComponent,
    ...props
}: RouteProps){
    const location = useLocation();
    const matches = useMatches();
    const dispatch = useAppDispatch();
    const isUserAnonymous = useSelector(isAnonymous);
    const navigate = useNavigate();
    const {path: loginPath} = useSelector(getSlugInfo)(Slug.Login);

    const loginURL = addRetURL(loginPath, location, true);

    useOnMountUnsafe(
        () => {
            dispatch(locationChange({
                location,
                matches,
                slug,
                page
            }));
            if (isUserAnonymous && page.authRequired) {
                navigate(loginURL);
            }
        },
        [
            location.key,
            location.pathname,
            page.path,
            slug,
            isUserAnonymous,
            loginURL,
        ],
        location.key
    );
    return <Component {...props}/>;
}